import Image from "next/image";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import ReactModal from "react-modal";
import Button from "~/components/Button";
import Icon from "~/components/Icon";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import styles from "./DiscordModal.module.scss";
import { useTranslation } from "next-i18next";
import Trans from "~/types/translationKeys";

const DiscordModal: FC = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const isDiscord = sessionStorage.getItem("discord");

      if (typeof window !== "undefined" && !isDiscord) {
        // calculate scroll percentage
        const scrollPercentage = Math.ceil(
          (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
            100
        );
        if (scrollPercentage > 50) {
          setOpen(true);
          sessionStorage.setItem("discord", "true");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ReactModal
      closeTimeoutMS={250}
      isOpen={open}
      onRequestClose={onClose}
      style={{
        content: {},
        overlay: { zIndex: 10000, backgroundColor: "rgba(23, 24, 26, 0.50)" },
      }}
      className={styles.discord_modal}
    >
      <div className={styles.discord_modal_container}>
        <Icon
          name="close"
          className={styles.close_icon}
          onClick={onClose}
          color={Colors.GREY_500}
        />

        <div className={styles.left}>
          <div className={styles.image}>
            <Image
              src="/images/discord.png"
              alt="Discord"
              fill
              className="object-contain"
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.container}>
            <Text type="headline-4" color={Colors.GREY_800}>
              {t(Trans.unlock_free_add_ons_and_talk_to_support_in_real_time)}
            </Text>
            <Text color={Colors.GREY_600}>
              {t(
                Trans.stay_up_to_date_and_get_exclusive_tips_and_perks_by_joining_the_cx_genie_discord_community
              )}
            </Text>
            <Link
              href="https://discord.gg/B3heXRJ5dc"
              target="_blank"
              aria-label="Discord"
            >
              <Button icon="discord" color={Colors.GREY_00}>
                {t(Trans.join_now)}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default DiscordModal;
