import Trans from "~/types/translationKeys";

export const Menus = [
  // {
  //   text: Trans.discover,
  //   link: "/#key-features",
  //   key: "discover",
  // },
  // {
  //   text: Trans.features,
  //   url: "/#features",
  //   key: "features",
  // },
  {
    text: Trans.pricing,
    url: "/#pricing",
    key: "pricing",
  },
  {
    text: Trans.ai_studio,
    url: "/ai-studio",
    key: "ai-studio",
  },
  {
    text: Trans.referral,
    url: "/referral",
    key: "referral",
  },
  // {
  //   text: Trans.tools,
  //   submenu: [
  //     // {
  //     //   label: Trans.e_commerce_chatbot,
  //     //   link: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-up?source=landing_page`,
  //     // },
  //   ],
  //   key: "tools",
  // },
  {
    text: Trans.resources,
    submenu: [
      {
        text: Trans.product_videos,
        url: "/resources/product-videos",
      },
      {
        text: Trans.blogs,
        url: "/resources/blogs",
      },
      {
        text: Trans.case_studies,
        url: "/resources/case-study",
      },
      {
        text: Trans.releases,
        url: "/resources/releases",
      },
    ],
    key: "resources",
  },
];
