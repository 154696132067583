import { useTranslation } from "react-i18next";
import Trans from "~/types/translationKeys";

const getDiscordLink = (text: string) => {
  return `<a href="https://discord.gg/bTs9AvZrFv" target="_blank">${text}</a>`;
};

export const useDiscordBanners = () => {
  const { t } = useTranslation();

  return [
    {
      id: "unlock_documenttation",
      content: [
        t(Trans.unlock_complete_documentation_banner),
        `${getDiscordLink(t(Trans.join_discord_banner))} ${t(
          Trans.for_access_to_comprehensive_cxgenie_documentation_and_detailed_features_banner
        )}`,
      ],
      image: "/svgs/discord_small_white_logo_blue_outline.svg",
      mobileImage: "/svgs/discord_mobile_small_white_logo_blue_outline.svg",
    },
    {
      id: "free_add_on",
      content: [
        t(Trans.exclusive_free_add_ons_banner),
        `${getDiscordLink(t(Trans.join_our_discord_banner))} ${t(
          Trans.community_and_get_access_to_free_cxgenie_add_ons_dont_miss_out_banner
        )}`,
      ],
      image: "/svgs/discord_small_white_logo_blue_outline.svg",
      mobileImage: "/svgs/discord_mobile_small_white_logo_white_outline.svg",
    },
    {
      id: "need_help",
      content: [
        t(Trans.need_help_get_instant_support_banner),
        `${getDiscordLink(t(Trans.join_our_discord_banner))} ${t(
          Trans.for_real_time_support_from_our_team_and_community_your_question_answered_fast_banner
        )}`,
      ],
      image: "/svgs/discord_small_blue_logo_grey_outline.svg",
      mobileImage: "/svgs/discord_mobile_small_blue_logo_grey_outline.svg",
    },
    {
      id: "chat_with_developers",
      content: [
        t(Trans.chat_with_developers_banner),
        `${getDiscordLink(t(Trans.join_our_discord_banner))} ${t(
          Trans.to_talk_directly_with_the_devs_share_your_feedback_and_ideas_banner
        )}`,
      ],
      image: "/svgs/discord_small_blue_logo_blue_outline.svg",
      mobileImage: "/svgs/discord_mobile_small_blue_logo_blue_outline.svg",
    },
    {
      id: "report_bug",
      content: [
        t(Trans.report_and_resolve_bugs_fast_banner),
        `${getDiscordLink(t(Trans.join_our_discord_banner))} ${t(
          Trans.to_report_bugs_and_get_them_fixed_quickly_help_us_improve_cxgenie_banner
        )}`,
      ],
      image: "/svgs/discord_small_white_logo_blue_outline.svg",
      mobileImage: "/svgs/discord_mobile_small_white_logo_white_outline.svg",
    },
    {
      id: "join_conversation",
      content: [
        t(Trans.join_the_conversation_banner),
        `${t(Trans.be_part_of_our_engaged_banner)} ${getDiscordLink(
          t(Trans.discord_community_banner)
        )}. ${t(
          Trans.share_insights_tips_and_tricks_with_fellow_users_banner
        )}`,
      ],
      image: "/svgs/discord_small_white_logo_grey_outline.svg",
      mobileImage: "/svgs/discord_mobile_small_white_logo_white_outline.svg",
    },
    {
      id: "stay_in_loop",
      content: [
        t(Trans.stay_in_the_loop_banner),
        `${getDiscordLink(t(Trans.join_discord_banner))} ${t(
          Trans.for_exclusive_announcements_and_updates_be_the_first_to_know_banner
        )}`,
      ],
      image: "/svgs/discord_small_white_logo_white_outline.svg",
      mobileImage: "/svgs/discord_mobile_small_white_logo_white_outline.svg",
    },
  ];
};
