import classNames from "classnames";
import { useTranslation } from "next-i18next";
import React from "react";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import Trans from "~/types/translationKeys";
import styles from "./Integrations.module.scss";

const Integrations: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section id="integrations" className={styles.integrations}>
      <div className={styles.wrapper}>
        <div className={styles.line} data-aos="fade-up" />

        <div className={styles.line_2} data-aos="fade-up" />

        <h2 className={styles.title} data-aos="fade-up">
          {t(Trans.why_cx_genie_title)}
        </h2>

        <p className={styles.description} data-aos="fade-up">
          {t(Trans.why_cx_genie_desc)}
        </p>

        <div className={styles.btns} data-aos="fade-up">
          <div className="flex flex-row items-center gap-5 md:flex-col md:gap-0">
            <div className={classNames(styles.button, styles.sdk)}>1</div>
            <div className={styles.content_box}>
              <Text type="title-1" color={Colors.ACCENT_BLUE}>
                {t(Trans.comprehensive_all_in_one_customer_support_solution)}
              </Text>
              <Text>
                {t(Trans.replaces_multiple_tools_with_one_integrated_platform)}
              </Text>
            </div>
          </div>
          <div className="flex flex-row items-center gap-5 md:flex-col md:gap-0">
            <div className={classNames(styles.button, styles.react_native)}>
              2
            </div>
            <div className={styles.content_box}>
              <Text type="title-1" color={Colors.ACCENT_VIOLET}>
                {t(Trans.high_customization)}
              </Text>
              <Text>
                {t(Trans.tailor_solutions_to_fit_your_unique_business_needs)}
              </Text>
            </div>
          </div>
          <div className="flex flex-row items-center gap-5 md:flex-col md:gap-0">
            <div className={classNames(styles.button, styles.flutter)}>3</div>
            <div className={styles.content_box}>
              <Text type="title-1" color={Colors.ACCENT_ORANGE}>
                {t(Trans.ai_driven)}
              </Text>
              <Text>
                {t(
                  Trans.leverage_advanced_AI_to_deliver_smarter_faster_and_more_accurate_customer_support
                )}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integrations;
