import { FC, Fragment, useEffect, useState } from "react";
import styles from "./DiscordBanner.module.scss";
import classNames from "classnames";
import { Container } from "~/components/Container";
import Image from "next/image";
import Icon from "~/components/Icon";
import LifetimeBanner from "../LifetimeBanner";
import { useDiscordBanners } from "~/components/HeaderBanner/DiscordBanner/data";
import { isTurnOffLTD } from "~/utils/time";

const DiscordBanner: FC = () => {
  const [shouldShow, setShouldShow] = useState(true);
  const banners = useDiscordBanners();
  const [selectedBanner, setSelectedBanner] = useState<any>();

  const [shouldShowLifetimeBanner, setShouldShowLifetimeBanner] =
    useState(false);
  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * (banners.length + 1));
    if (randomNumber >= banners.length && !isTurnOffLTD()) {
      setShouldShowLifetimeBanner(true);
    } else {
      setSelectedBanner(banners[randomNumber]);
    }
  }, []);

  function onClose() {
    setShouldShow(false);
  }

  if (shouldShowLifetimeBanner) {
    return <LifetimeBanner />;
  }

  if (!shouldShow || !selectedBanner) {
    return null;
  }

  return (
    <div
      className={classNames(styles.discord_banner, styles[selectedBanner.id])}
    >
      <Container className="flex flex-col items-center lg:gap-2 lg:flex-row">
        <Image
          src={selectedBanner.image}
          alt="Discord"
          width={180}
          height={180}
          className="absolute bottom-0 left-0 top-0 object-fill h-full !hidden sm:!block -z-[1]"
        />
        <Image
          src={selectedBanner.mobileImage}
          alt="Discord"
          width={160}
          height={160}
          className="absolute bottom-0 left-0 !block sm:!hidden -z-[1]"
        />
        {selectedBanner.content.map((contentItem: string, index: number) => (
          <Fragment key={index}>
            {index === 0 ? (
              <div dangerouslySetInnerHTML={{ __html: contentItem }} />
            ) : (
              <div className="inline">
                <Image
                  src="/svgs/discord.svg"
                  alt="Discord"
                  width={24}
                  height={24}
                  className="inline w-5 h-5 mr-1 sm:w-6 sm:h-6"
                />
                <div
                  className="inline"
                  dangerouslySetInnerHTML={{ __html: contentItem }}
                />
              </div>
            )}
            {index < selectedBanner.content.length - 1 && (
              <div className="hidden lg:block">•</div>
            )}
          </Fragment>
        ))}
      </Container>
      <Icon name="close" className={styles.close_icon} onClick={onClose} />
    </div>
  );
};

export default DiscordBanner;
